import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from 'components/Container';

import Main from 'layouts/Main';
import {
  AboutTop
} from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const JobListing = () => {
  return (
    <Container maxWidth={0.8}  paddingTop={'0 !important'} >
      <AboutTop />
      </Container>
  );
};

export default JobListing;
