/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from '@mui/material';



const Hero = () => {
  const theme = useTheme();
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });
  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography 
            variant='h4'
            align = 'center'>
            GUIDELINES AND CONTACT DETAILS
            <br />
            <br />
            </Typography>
            <strong> ADMISSIONS </strong>
            <Typography>
            <br />
          </Typography>
          <Typography >
            
          The foundation of academics and success in life is laid on the strength of the child where his family, facilitators and peers play the role of providers.<br/><br/>
          Welcome to National Public School, Yeshwanthpur, an institution which strives to strike a balance between pursuits and goals through the dynamic process of education for life long learners .<br/> <br/>
          Admission to National Public School, Yeshwanthpur, is open to all children from Pre-KG, Kindergarten and Classes 1 to 9 & 11 and to I-5 Academy for Montessori.<br/><br/>
           
            
            <br />
            <strong>REGISTRATION FOR ADMISSION
            </strong><br /> <br />
            Registration form is available online on our website from 18/10/2022.<Link href='**'> Click Here </Link>
            <br />
            <br />
            <strong>Kindly note the age criteria as on 1st June 2023:</strong>
            <br />
            <br />
            •	For Montessori I and Pre-K registration, the student should have completed 3 years.
           <br/>•	For Montessori II and Kindergarten I registration, the student should have completed 4 years.
           <br/>•	For Montessori III and Kindergarten II registration, the student should have completed 5 years
           <br/>•	For Class 1 registration, the student age should have completed 6 years.
<br/><br/>

            <strong>DOWNLOAD THE COMPLETED FORM AND SUBMIT THE PRINTED COPY ALONG WITH THE FOLLOWING DOCUMENTS ATTESTED BY PARENTS DURING INTERACTION OR ENTRANCE EXAM:

            </strong>
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {[
                
                ' Passport size photo of the student ',
                " Student’s Birth Certificate.",
                ' Immunisation Card (applicable upto Class 1) ',
                '	Class 2  & above applicants should provide previous 3 years academic progress report from the previous school. Class 1 applicants should submit Study Certificate from the previous school. ',
                ' Student’s Aadhar Card.  ',
                ' Parents’ Aadhar Card.   ',
                ' Registration Fee - ₹ 500/- (Online Payment)',
              ].map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid>
            <br />
            <Typography>
            We appreciate the interest evinced by the parents seeking admission at National Public School, Yeshwanthpur. We would like to inform that we have very limited seats available for admission to all of the classes from Classes 1 to 9 and 11. The seats will be available in the open category according to the priority list given below:
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {[
                ' The first priority is for siblings ',
                ' The second priority is for children of our teachers and other staff members. ',
                ' Priority for admission is also listed for Alumni of National Public School ',
                ' Subsequent seats will be open to others.  ',
                '	Applications for admission to Mont II, Mont III, KG II and classes 2-9 are based on vacancies.',
                ' Class 10 & Class 12 admissions are based on Interstate transfer.                ',
              ].map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid><br  />

            <Typography >
             To have an effective teaching & learning process, we maintain a limited class strength. Considering the limited number of seats available, it is expected that those who seek admission will realize and understand the constraints faced by the institution.
            </Typography>
            <br/>
            <Typography >
            Admission to classes 1 to 9 & 11 are finalized based on the performance in the written admission test conducted in the month of January 2023.<br/>
            </Typography>
              <Typography><br/>
              ADDRESS FOR COMMUNICATION
              <br/>NATIONAL PUBLIC SCHOOL<br/><br/>
              #9/1, Pipeline Road, Raghavendra Layout<br/>
(Behind RNS Motors), Yeshwanthpur,<br/>
Bangalore - 560 022<br/>

              Phone		: 091-080-23571220, 091-6364071122<br/>
		          Email		: admissions@npsypr.edu.in<br/>


              </Typography>
          
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretched', sm: 'center' }}
          justifyContent={'center'}
        >
          {/* <Button
            component={'a'}
            variant="contained"
            color="primary"
            size="large"
            fullWidth={isMd ? false : true}
            href={'https://mui.com/store/items/the-front-landing-page/'}
            target={'_blank'}
          >
            Purchase now
          </Button> */}
          <Box
            marginTop={{ xs: 2, sm: 0 }}
            marginLeft={{ sm: 2 }}
            width={{ xs: '100%', md: 'auto' }}
          >
            {/* <Button
              component={'a'}
              href={'/docs/introduction'}
              variant="outlined"
              color="primary"
              size="large"
              fullWidth={isMd ? false : true}
            >
              View documentation
            </Button> */}
          </Box>
        </Box>
      </Box>
     
    </Box>
  );
};

export default Hero;